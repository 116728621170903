<template>
  <div>
    <div class="container-fluid py-5">
      <div class="bg-white p-2">
        <form class="" method="post" accept-charset="utf-8" action="">
          <div class="row align-items-center">
            <div class="col-sm">
              <div class="form-floating">
            <input type="date" class="form-control" id="floatingInput">
            <label for="floatingInput">Date de début</label>
          </div>
            </div>
            <div class="col-sm">
              <div class="form-floating">
            <input type="date" class="form-control form-control" id="floatingInput2">
            <label for="floatingInput2">Date de fin</label>
          </div>
            </div>
            <div class="col-sm">
              <div class="d-grid gap-2">
                  <button type="submit" class="btn btn-primary btn-lg">Rechercher</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="container-fluid mb-5">
      <div class="d-flex flex-wrap justify-content-between mb-3">
          <h4 class="fw-bold">Vue d'ensemble</h4>
          <div class="dropdown">
          <button class="btn style_btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Exportation</button>
          <ul class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton1">
            <li class="border">
              <a class="dropdown-item" href="#"><i class="me-2 bi-file-earmark-word-fill"></i>Exporter en Word</a>
            </li>
            <li class="border">
              <a class="dropdown-item" href="#"><i class="me-2 bi-file-excel-fill"></i>Exporter en Excel</a>
            </li>
          </ul>
        </div>
        </div>
        <de-statistic />
      </div>
  </div>
</template>

<script>
import DeStatistic from '../../../components/espace/demandeur/statistic/DeStatistic.vue'
export default {
  components: { DeStatistic },
}
</script>

<style scoped>
</style>